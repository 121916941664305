/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/semantic-ui-calendar@0.0.8/dist/calendar.min.css
 * - /npm/fullcalendar@3.4.0/dist/fullcalendar.min.css
 * - /npm/alertifyjs@1.11.1/build/css/alertify.min.css
 * - /npm/alertifyjs@1.11.1/build/css/themes/semantic.min.css
 * - /npm/cropper@3.1.6/dist/cropper.min.css
 * - /npm/jquery-contextmenu@2.7.1/dist/jquery.contextMenu.min.css
 * - /npm/daterangepicker@3.1.0/daterangepicker.css
 * - /npm/summernote@0.8.15/dist/summernote-lite.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
